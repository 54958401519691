import { inject } from '@angular/core';
import { type CanActivateFn, Router } from '@angular/router';
import { UserContextService } from '../../shared/services/user-context/user-context.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';

export const onboardGuard: CanActivateFn = () => {
  const router = inject(Router);
  const userContextService = inject(UserContextService);

  return toObservable(userContextService.userPermissions).pipe(
    filter((permissions) => Array.isArray(permissions)),
    map((permissions) => {
      if (permissions.includes('DATAPLANE_CONFIGURATION_WRITE') && userContextService.isTenantOnboarded()) {
        return router.createUrlTree(['/onboard/dataplane']);
      }

      return true;
    }),
  );
};
