import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input } from '@angular/core';
import { AuthService } from '../../shared/services/auth/auth.service';
import { StorageService } from '../../shared/services/storage/storage.service';
import { Router } from '@angular/router';
import { LoadingSpinnerComponent } from '../../shared/components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'dpqa-callback',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent],
  template: `
    <div class="loading-container">
      <dpqa-loading-spinner />
    </div>
  `,
  styles: [
    `
      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10rem;
      }
    `,
  ],
})
export class CallbackComponent implements OnInit {
  private authService = inject(AuthService);
  private storageService = inject(StorageService);
  private router = inject(Router);

  public code = input.required<string>();
  public state = input.required<string>();

  ngOnInit(): void {
    const state = this.storageService.get<string>('state');

    if (state && this.state() === state) {
      this.authService.exchangeAccessToken(this.code()).subscribe(() => {
        const redirectUri = this.storageService.get<string>('redirect_uri');

        this.router.navigateByUrl(redirectUri || '/slides', { replaceUrl: true });
      });
    }
  }
}
