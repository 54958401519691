import { Component, OnInit, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingSpinnerComponent } from '../../shared/components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'dpqa-re-route',
  standalone: true,
  imports: [LoadingSpinnerComponent],
  template: `
    <div class="loading-container">
      <dpqa-loading-spinner />
    </div>
  `,
  styles: [
    `
      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10rem;
      }
    `,
  ],
})
export class ReRouteComponent implements OnInit {
  private router = inject(Router);

  public route = input<string>();

  ngOnInit(): void {
    this.router.navigate([this.route() ? this.route() : '/slides'], {
      queryParamsHandling: 'merge',
      queryParams: { route: undefined },
      replaceUrl: true,
    });
  }
}
