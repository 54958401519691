import { Router, type CanActivateFn } from '@angular/router';
import { StorageService } from '../../shared/services/storage/storage.service';
import { inject } from '@angular/core';
import { UserContextService } from '../../shared/services/user-context/user-context.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';

const CURRENT_TENANT = 'current_tenant';

export const tenantGuard: CanActivateFn = (_, state) => {
  const router = inject(Router);
  const userContextService = inject(UserContextService);
  const storageService = inject(StorageService);

  return toObservable(userContextService.possibleTenants).pipe(
    filter((possibleTenants) => possibleTenants?.length > 0),
    map((possibleTenants) => {
      if (possibleTenants.length === 1) {
        storageService.set(CURRENT_TENANT, possibleTenants[0].id);
        return true;
      }

      if (possibleTenants.length > 1 && storageService.get(CURRENT_TENANT)) {
        return true;
      }

      storageService.set('redirect_uri', state.url);

      return router.createUrlTree(['/tenant/selection']);
    }),
  );
};
