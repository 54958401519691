import { HttpClient, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { type ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NZ_I18N, en_US, fr_FR } from 'ng-zorro-antd/i18n';
import { provideCharts } from 'ng2-charts';
import {
  ArcElement,
  CategoryScale,
  DoughnutController,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import { appRoutes } from './app.routes';
import { authorizationInterceptor } from './core/interceptors/authorization.interceptor';
import { RumErrorHandler } from './shared/error-handlers/rum-error-handler';
import { RUMService } from './shared/services/rum/rum.service';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export class LocaleId extends String {
  private translateService = inject(TranslateService);

  override toString(): string {
    return this.translateService.getBrowserCultureLang() || 'en_US';
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withComponentInputBinding(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
    ),
    provideHttpClient(withInterceptors([authorizationInterceptor])),
    provideCharts({
      defaults: {
        plugins: {
          tooltip: { enabled: false },
          legend: { display: false },
        },
      },
      registerables: [
        DoughnutController,
        ArcElement,
        LineController,
        CategoryScale,
        LinearScale,
        TimeScale,
        PointElement,
        LineElement,
        Tooltip,
      ],
    }),
    importProvidersFrom(
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
    provideAnimations(),
    {
      provide: LOCALE_ID,
      useClass: LocaleId,
      deps: [TranslateService],
    },
    {
      provide: NZ_I18N,
      useFactory: () => {
        const localId = inject(LOCALE_ID);
        switch (localId) {
          case 'en_US':
            return en_US;
          case 'fr_FR':
            return fr_FR;
          default:
            return en_US;
        }
      },
    },
    {
      provide: ErrorHandler,
      useClass: RumErrorHandler,
      deps: [RUMService],
    },
  ],
};
