import { Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconAlertComponent } from '../../shared/components/icon-alert/icon-alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserContextService } from '../../shared/services/user-context/user-context.service';
import { ButtonComponent } from '../../shared/components';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'dpqa-error-page',
  standalone: true,
  imports: [CommonModule, IconAlertComponent, ButtonComponent, RouterLink, TranslateModule],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.less',
})
export class ErrorPageComponent {
  private userContext = inject(UserContextService);

  public amountOfTenants = computed(() => this.userContext.possibleTenants()?.length || 0);

  public errorCode = input.required<string>({ alias: 'error-code' });

  public errorIcon = computed(() => {
    switch (this.errorCode()) {
      case '403':
        return 'no-access';
      default:
        return 'x-circle';
    }
  });
}
