import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationComponent } from '../../shared/components';

@Component({
  selector: 'dpqa-with-navigation',
  standalone: true,
  imports: [RouterOutlet, NavigationComponent],
  template: `
    <dpqa-navigation />
    <router-outlet />
  `,
})
export class WithNavigationComponent {}
